import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {useQuery} from "@tanstack/react-query";
import {GET_CMS_BLOCK, getPromoProductsAttributes} from '../../queries';
import useLocalStorageState from "use-local-storage-state";

interface ProductsAttributes {
    id;
    sku;
    name;
    type_id: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    price_range: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    special_price: number;
    special_from_date: string;
    special_to_date: string;
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
}

type OptionsData = {
    pack_name: string;
    total_value: string;
    required_value: string;
    skus: string;
    gift: string;
}[];

interface PromoBlockResponse {
    data: {
        cmsBlocks: {
            items: any[]
        }
    }
}

interface ProductAttributeContextValue {
    productAttributes: ProductsAttributes[];
    appConfig: OptionsData;
    packageNames: string[];
    blockData1: PromoBlockResponse | null;
    blockData2: PromoBlockResponse | null;
    loading: boolean;
    error: any;
}

const PromoBulkProductsContext = createContext<ProductAttributeContextValue | undefined>(undefined);

export const PromoBulkProductsProvider: React.FC<{ children: ReactNode, appConfig: OptionsData }> = (
    {
        children,
        appConfig
    }
) => {
    const [productAttributes, setProductAttributes] = useState<ProductsAttributes[]>([]);
    const [blockData1, setBlockData1] = useState<PromoBlockResponse | null>(null);
    const [blockData2, setBlockData2] = useState<PromoBlockResponse | null>(null);
    const [localData, setLocalData] = useLocalStorageState('promo_data', {defaultValue: null});
    const timeToRefresh = 30 * 60 * 1000;// 30 minutes
    const pageSize = '100';
    const currentPage = '1';


    const isValidData = (data) => {
        if (!data || !data.timestamp) return false;
        const now = new Date().getTime();
        const elapsed = now - data.timestamp;
        return elapsed < timeToRefresh;
    };

    const packageNames = appConfig.map(pack => pack.pack_name);

    const allSkusString = appConfig.reduce((acc, pack) => {
        let result = acc + (acc ? ", " : "") + pack.skus;

        if (pack.gift && pack.gift.trim() !== "") {
            result += ", " + pack.gift.trim();
        }

        return result;
    }, "");

    const skusArray = allSkusString.split(',')

    const cmsBlockQuery = useQuery({
        queryKey: ['cmsBlocks'],
        queryFn: async () => {
            return request(GET_CMS_BLOCK, {
                identifier1: "bulkPromo_banner1",
                identifier2: "bulkPromo_banner2"
            });
        },
        enabled: true,
        staleTime: 900000,
    });

    useEffect(() => {
        if (cmsBlockQuery.data) {
            const blockData1 = cmsBlockQuery.data.data.block1.items[0];
            const blockData2 = cmsBlockQuery.data.data.block2.items[0];
            setBlockData1(blockData1);
            setBlockData2(blockData2);
        }
    }, [cmsBlockQuery.isSuccess]);

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes', skusArray],
        queryFn: async () => {
            const variables = {
                skus: skusArray,
                pageSize: pageSize,
                currentPage: currentPage
            };
            return request(getPromoProductsAttributes, variables);
        },
        enabled: skusArray.length > 0 && (!localData || !isValidData(localData)),
        initialData: localData && isValidData(localData) ? localData.data : undefined,
        onSuccess: (response) => {
            const packList = response?.data?.products?.items;
            const formattedData = {
                data: packList,
                timestamp: new Date().getTime()
            };
            setLocalData(formattedData);
            setProductAttributes(packList);
        }
    });

    useEffect(() => {
        if (localData && isValidData(localData)) {
            setProductAttributes(localData.data);
        }
    }, [localData]);

    return (
        <PromoBulkProductsContext.Provider value={
            {
                productAttributes,
                appConfig,
                packageNames,
                blockData1,
                blockData2,
                loading: productAttributesQuery.isLoading,
                error: productAttributesQuery.error,
            }
        }>
            {children}
        </PromoBulkProductsContext.Provider>
    );
};

export const usePromoBulkProducts = () => {
    const context = useContext(PromoBulkProductsContext);
    if (!context) {
        throw new Error('useProductAttributes must be used within a PromoBulkProductsContext');
    }
    return context;
};
