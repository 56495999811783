import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {getGuestCart, getCustomerCart, getAddToCart, getCreateGuestCart, getBundleOptions} from "../../queries";

//bundle options
type OptionType = {
    uid: string;
    label: string;
    quantity: number;
    can_change_quantity: boolean;
    product: {
        sku: string;
        name: string;
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
    }
};

type ItemType = {
    title: string;
    type: string;
    options: OptionType[];
};

type BundleOptionType = {
    sku: string;
    __typename: string;
    dynamic_sku: number;
    dynamic_price: boolean;
    items: ItemType[] | null;
};

//checkout
type CartItemInputType = {
    sku: string;
    quantity: number;
    selected_options?: string[];
}

type BundleCheckoutStateType = {
    //bundle options
    productSku: string;
    bundleOptions: BundleOptionType[] | null;
    loading: boolean;
    error: any;
    total?: number;
    updateTotal?: (priceChange: number) => void;
    selectedOptions?: { [key: string]: number };
    handleCheckboxChange?: (uid: string, price: number, isChecked: boolean) => void;
    handleSelectOption?: (uid: string, price: number, isChecked: boolean) => void;
    //checkout
    handleAddBundleToCart: (data) => void;
    addingBundleToCart: string;
    errorMessage: string | null;
};

const BundleCheckoutStateContext = createContext<BundleCheckoutStateType | undefined>(undefined);

export const BundleCheckoutProvider: React.FC<{ children: React.ReactNode, productSku: string }> = (
    {
        children,
        productSku
    }) => {

    const [bundleOptions, setBundleOptions] = useState<BundleOptionType[] | null>(null);
    const [total, setTotal] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const roundPrice = (price: number): number => {
        return Math.round(price * 100) / 100;
    };

    const findOptionPrice = (uid: string): number => {
        let foundPrice = 0;
        bundleOptions?.forEach(item => {
            item.items?.forEach(bundleItem => {
                bundleItem.options.forEach(option => {
                    if (option.uid === uid) {
                        const basePrice = option.product.price_range.minimum_price.final_price.value;
                        foundPrice = roundPrice(basePrice * option.quantity);
                    }
                });
            });
        });
        return foundPrice;
    };

    const updateTotal = (priceChange: number) => {
        setTotal((prevTotal) => roundPrice(prevTotal + priceChange));
    };

    const handleSelectOption = (uid, priceValue, isChecked) => {
        const newSelectedOptions = {...selectedOptions};

        if (isChecked) {
            const originalPrice = findOptionPrice(uid);
            newSelectedOptions[uid] = originalPrice;
            updateTotal(originalPrice);
        } else {
            const priceToRemove = selectedOptions[uid];
            delete newSelectedOptions[uid];
            updateTotal(-priceToRemove);
        }

        setSelectedOptions(newSelectedOptions);
    };

    const handleCheckboxChange = (uid, priceValue, isChecked) => {
        handleSelectOption(uid, priceValue, isChecked);
    };

    const productOptionsQuery = useQuery({
        queryKey: ['bundleOptions', productSku],
        queryFn: async () => {
            return request(getBundleOptions, {sku: productSku});
        },
        enabled: !!productSku,
        refetchOnWindowFocus: false,

        onSuccess: (data) => {
            const items = data?.data?.products?.items;
            setBundleOptions(items);

            const initialSelectedOptions = {};
            let initialTotal = 0;

            items?.forEach(item => {
                item.items?.forEach(bundleItem => {
                    bundleItem.options.forEach(option => {
                        const uid = option.uid;
                        const basePrice = option.product.price_range.minimum_price.final_price.value;
                        const totalPrice = roundPrice(basePrice * option.quantity);

                        initialSelectedOptions[uid] = totalPrice;
                        initialTotal = roundPrice(initialTotal + totalPrice);
                    });
                });
            });

            setSelectedOptions(initialSelectedOptions);
            setTotal(initialTotal);
        }
    });

    useEffect(() => {
        if (productOptionsQuery?.data) {
            setBundleOptions(productOptionsQuery?.data?.data?.products?.items);
        }
    }, [productOptionsQuery.data]);

    const [cartId, setCartId] = useState(null);
    const [addingBundleToCart, setAddingBundleToCart] = useState<string>('null');

    const getGuestCartIdMutation = useMutation({
        mutationKey: ['createGuestCart'],
        mutationFn: () => request(getCreateGuestCart),
        onSuccess: (response) => {
            const cartId = response.data.createEmptyCart;
            setCartId(cartId);
        }
    });

    const getGuestAddToCartMutation = useMutation({
        mutationKey: ['addToGuestCart'],
        mutationFn: (data: CartItemInputType) => request(getAddToCart, {cartId, cartItems: [data]}),
        onSuccess: (response) => {
            setAddingBundleToCart('null');
            const errors = response.data.addProductsToCart.user_errors;
            if (errors && errors.length > 0) {
                setErrorMessage(errors.map(error => error.message).join(', '));
            } else {
                setErrorMessage(null);
            }
            document.dispatchEvent(new CustomEvent('customEventReload', {detail: ['cart']}));
            document.dispatchEvent(new CustomEvent('specialCartEventPopup', {}));
        }
    });

    const handleAddBundleToCart = async (data: CartItemInputType) => {
        setAddingBundleToCart(data.sku);

        const selectedOptionsUids = Object.keys(selectedOptions);

        const cartItemData: CartItemInputType = {
            ...data,
            selected_options: selectedOptionsUids,
        };

        if (!cartId) {
            getGuestCartIdMutation.mutate(undefined, {
                onSuccess: (response) => {
                    setAddingBundleToCart(null);
                    setCartId(response.data.createEmptyCart);
                    getGuestAddToCartMutation.mutate(cartItemData);
                }
            });
        } else {
            getGuestAddToCartMutation.mutate(cartItemData);
        }
    };

    return (
        <BundleCheckoutStateContext.Provider value={
            {
                productSku,
                bundleOptions,
                loading: productOptionsQuery.isLoading,
                error: productOptionsQuery.error,
                total,
                updateTotal,
                selectedOptions,
                handleCheckboxChange,
                handleSelectOption,
                handleAddBundleToCart,
                addingBundleToCart,
                errorMessage,
            }
        }>
            {children}
        </BundleCheckoutStateContext.Provider>
    );
};

export const useBundleCheckoutState = () => {
    const context = useContext(BundleCheckoutStateContext);
    if (context === undefined) {
        throw new Error("useBundleCheckoutState must be used within a BundleCheckoutProvider");
    }
    return context;
};
