import React, {useEffect, useState} from "react";
import {useProductAttributes, findCustomAttributeValue} from "../../../state";

export const StockStatus = () => {
    const {customAttributes, categories, loading} = useProductAttributes() || {};
    const originalStockStatus = findCustomAttributeValue(customAttributes, 'status_stock')
    const [stockStatus, setStockStatus] = useState(originalStockStatus);
    const [showTooltip, setShowTooltip] = useState(false);

    const hasCategory = (id) => categories?.some(category => category.id === id);
    const isInLichidari = hasCategory(363);

    useEffect(() => {
        if (isInLichidari) {
            setStockStatus('Ultimul produs din stoc');
        } else {
            setStockStatus(originalStockStatus);
        }
    }, [isInLichidari, originalStockStatus]);

    if (loading) {
        return null
    }

    if (customAttributes?.find(attr => attr.attribute_code === 'info_disp')?.value === 'Nu') {
        return null;
    }
    
    const lastItem = stockStatus === 'Ultimul produs din stoc'

    const getStockValue = () => {
        switch(stockStatus) {
            case 'In stoc':
                return 'Avem mai mult de 5 bucati in stoc.';
            case 'Stoc limitat':
                return 'Avem mai putin de 5 bucati in stoc.';
            case 'Ultimul produs din stoc':
                return 'Produsul se afla in stocul fizic al Magaziei.';
            case 'La comanda':
            case 'La furnizor':
            case 'In stoc furnizor':
            case 'Stoc extern cu livrare rapida':
                return 'Produsul nu este disponibil in stocul Magaziei, dar este posibil sa fie in depozitul furnizorului. ' +
                    'Daca inregistrezi o comanda pentru acest produs, unul dintre consultantii nostri de vanzari va verifica ' +
                    'disponibilitatea produsului la furnizor si te va contacta in cel mai scurt timp posibil.';
            case 'Rezervat':
                return 'Produsul se afla in stocul fizic al Magaziei, dar apare rezervat pentru un client. ' +
                    'Daca sunteti interesat, va rog sa ne contactati sa verificam daca rezervarea pe acest produs mai este de actualitate sau nu.';
            default:
                return '';
        }
    }

    return (
        <>
            {stockStatus && (
                <div className={'relative flex flex-row justify-start items-center gap-2 mb-2 xl:mb-4'}>
                    <div className={'flex justify-center items-center h-[50px]  cursor-pointer'}
                         onMouseEnter={() => setShowTooltip(true)}
                         onMouseLeave={() => setShowTooltip(false)}
                    >
                        <p className={
                            `react-css ${lastItem ? 'bg-black-friday-btn' : 'bg-custom-green'} ` +
                            `p-2 font-montserrat font-bold text-white text-center text-sm leading-none rounded-xl w-fit `
                        }>
                            {stockStatus}
                        </p>
                    </div>
                    <svg
                        className={`w-4 h-4 xl:w-5 xl:h-5 cursor-pointer fill-current text-gray-500`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        <path
                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3
                                0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24
                                24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                    </svg>
                    {showTooltip && (
                        <div
                            className={'max-w-[300px] min-w-[200px] absolute top-[50px] xl:top-0 right-[1%] p-2 border rounded-md shadow-lg ' +
                                'text-custom-gray text-lg font-nunito text-center bg-cards-bg'}>
                            {getStockValue()}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
