import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const LinkedSkeleton = () => {

    return (
        <div className={'flex flex-col justify-center items-center md:items-start xl:justify-start '}>
            <div className={'w-full h-80 justify-center xl:justify-start mb-3 xl:mb-5'}>
                <Skeleton width={'100%'} className={'w-full h-80'} count={1}/>
            </div>
        </div>
    );
}
