import React, {useState, useEffect} from 'react';
import {ExpandButton} from '../../../../../general';
import {useProductAttributes} from '../../../state';
import {ProductDetailsSkeleton} from "../Skeleton/ProductDetailsSkeleton";

declare global {
    interface Window {
        appConfig: {
            attributes: { [key: string]: AttributeType };
        };
    }
}

type AttributeType = {
    label: string;
    value: string;
    code: string;
};

type ConfigType = {
    appConfig: {
        attributes: { [key: string]: AttributeType };
    };
};

export const InformatiiAditionale = (props: ConfigType) => {
    const {productName, loading, appConfig, categories} = useProductAttributes() || {};
    const [isExpanded, setIsExpanded] = useState(false);

    if (loading) return <ProductDetailsSkeleton/>;

    if (!appConfig?.attributes) {
        return null;
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleButtonClick = () => {
        toggleExpand();  // This will toggle the expansion state

        const element = document.getElementById('additionalInfo');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const lastCategory = categories?.[categories.length - 1];
    const categoryUrl = lastCategory ? `${window.BASE_URL}${lastCategory.url_path}.html` : '';

    return (
        <div className={'relative rounded-xl bg-white xl:mb-20 mb-10'} id={'additionalInfo'}>
            <div
                className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[450px]'} p-6`}>
                <div className={'react-css'}>
                    <h2 className={'react-css text-2xl font-black text-left text-custom-gray font-montserrat mb-8 flex'}>
                        <span className={'react-css w-2/5 xl:block hidden'}>Informatii aditionale</span>
                        <span
                            className={'react-css text-custom-gray font-montserrat font-bold text-center text-2xl'}>{productName}</span>
                    </h2>
                </div>
                <table className={'react-css table-auto border'}>
                    <thead>
                    </thead>
                    <tbody>
                    <tr className={'react-css border-b'}>
                        <td className={'react-css align-middle border text-base text-custom-gray font-montserrat font-semibold leading-5'}>Tip
                            produs
                        </td>
                        <td className={'react-css align-middle border text-lg font-montserrat text-custom-gray'}>
                            {lastCategory && (
                                <a href={categoryUrl} className={'hover:text-primary'}>
                                    {lastCategory.name}
                                </a>
                            )}
                        </td>
                    </tr>
                    {Object.entries(appConfig?.attributes).map(([key, attr]) => (
                        <tr className={'react-css border-b'} key={key}>
                            <td className={'react-css align-middle border text-base text-custom-gray font-montserrat font-semibold leading-5'}>{attr.label}</td>
                            <td className={'react-css align-middle border text-lg font-montserrat text-custom-gray'}>{attr.value}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* opacity efect over the closed div */}
            <div
                className={`react-css absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}></div>
            {/* The "See More/Less" button */}
            <div className={'react-css flex items-center justify-center xl:mb-0 mb-5'}>
                <ExpandButton
                    isExpanded={isExpanded}
                    onClick={handleButtonClick}
                    expandedText="Ascunde informatiile"
                    collapsedText="Vezi toate informatiile"
                />
            </div>
        </div>
    );
}
