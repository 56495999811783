import React, {useState, useEffect} from 'react';
import {ContextFormsProvider, useContextFormsState} from '../../../CrmForms';
import {FormFields} from '../../../CrmForms/components/CrmForms/FormFields';
import {usePromoBulkProducts} from "../../state";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {commonFormFields} from "../../../CrmForms/components/CrmForms/FormFieldsConfig";

export const CrmPacksForm = () => {
    const [showCrmLeadForm, setShowCrmLeadForm] = useState(true);
    const {packageNames} = usePromoBulkProducts();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const FormWithContext = () => {
        const {handleCreateForm, successMessage: contextSuccessMessage} = useContextFormsState();
        const [selectedPackage, setSelectedPackage] = useState('');
        const {register, formState: {errors}, handleSubmit, watch, setValue} = useForm();

        const watchPackage = watch('package');

        useEffect(() => {
            if (watchPackage) {
                setSelectedPackage(watchPackage);
            }
        }, [watchPackage]);

        useEffect(() => {
            if (packageNames.length > 0) {
                setValue('package', packageNames[0]);
                setSelectedPackage(packageNames[0]);
            }
        }, [packageNames, setValue]);

        const onSubmit = (formData) => {
            if (!selectedPackage) {
                return;
            }
            const formTitle = `Cerere oferta pentru ${selectedPackage}`;
            const updatedFormData = {
                ...formData,
                productSku: selectedPackage,
                title: formTitle
            };
            handleCreateForm(updatedFormData);
        };

        useEffect(() => {
            if (contextSuccessMessage) {
                setSuccessMessage(contextSuccessMessage);
                setFormSubmitted(true);
                setIsModalOpen(true);
                setShowCrmLeadForm(false);
            }
        }, [contextSuccessMessage]);

        return (
            <>
                <div className={'flex flex-col gap-1 mb-4'}>
                    <p className={'react-css text-left font-black font-montserrat text-sm text-custom-gray tracking-wide mb-0 xl:mb-6 w-full'}>
                        {'Selecteaza pachetul dorit:'}
                    </p>
                    <select
                        {...register('package', {
                            required: 'Selectarea unui pachet este obligatorie'
                        })}
                        className={'react-css text-custom-gray font-nunito font-normal text-left text-base rounded-3xl border border-gray-400 w-full h-12 p-2'}
                    >
                        {packageNames.map(name => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                    <ErrorMessage
                        errors={errors}
                        name="package"
                        render={({message}) => <span
                            className={'react-css font-nunito error-message text-red-500 text-base'}>{message}</span>}
                    />
                </div>
                <FormFields
                    fields={commonFormFields}
                    onSubmit={onSubmit}
                    productSku={selectedPackage}
                />
            </>
        );
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={'flex flex-col items-center mt-3 xl:mt-8'}>
            <div className={'react-css flex flex-col gap-3'}>
                <p className={'react-css text-center font-medium font-montserrat text-base xl:text-lg text-white tracking-wide mb-6'}>
                    {'Pentru a beneficia de oferta, contacteaza-ne: '}
                </p>
                <p className={'react-css text-center text-xl xl:text-2xl text-white font-montserrat font-bold'}>
                    {'Telefonic'}
                </p>
                <div className="flex flex-col items-center content-center">
                    <div className="flex justify-center mb-3">
                        <a href="tel:0371.710.072">
                            <i className="fas fa-phone w-16 h-16 font-black text-white text-4xl leading-none bg-custom-green
                                        rounded-full p-3 border-0 box-border"></i>
                        </a>
                    </div>
                    <div className="flex flex-col justify-evenly flex-initial">
                        <a className={'font-montserrat text-white font-bold text-base xl:text-lg leading-4.5 hover:text-white'}
                           href="tel:0371.710.072">0371.710.074
                        </a>
                    </div>
                </div>
            </div>

            {showCrmLeadForm && (
                <ContextFormsProvider productSku={''}>
                    <div
                        className={'relative react-css flex flex-col items-center justify-center align-center gap-2 w-full xl:w-1/2 xl:max-w-4xl pl-4 pr-4'}>
                        <p className={'react-css mt-3 xl:mt-8 text-center text-xl xl:text-2xl text-white font-montserrat font-bold'}>
                            {'Sau completand formularul de contact'}
                        </p>
                        <div
                            className={'react-css crm-lead-form flex flex-col space-y-2.5 rounded-xl bg-cards-bg shadow-custom-box-shadow xl:my-6 px-3 py-3 xl:px-8 xl:py-5 block w-full'}>
                            <FormWithContext/>
                        </div>
                    </div>
                </ContextFormsProvider>
            )}

            {formSubmitted && (
                <div className={'z-10'}>
                    {isModalOpen && (
                        <div className={'fixed top-0 left-0 w-full h-full flex items-center justify-center z-40'}>
                            <div
                                className={'react-css bg-background-gray p-6 rounded-xl shadow-lg w-4/5 xl:w-96 xl:max-w-96 p-6 ' +
                                    'z-50 absolute top-24 flex items-center justify-center flex-col border-solid border-2 border-main-orange'}>
                                <p className={'react-css text-center'}>{successMessage}</p>
                                <button onClick={handleClose}
                                        className={'react-css mt-4 bg-main-orange text-white px-4 py-2 rounded hover:bg-main-orange'}
                                        type="button">
                                    {'Inchide'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
