import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {CityList} from './CityList';
import {useContextFormsState} from "../../state";

export const FormFields = ({onSubmit, fields, productSku}) => {
    const {loading} = useContextFormsState();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm();

    // Set the product SKU if provided
    useEffect(() => {
        setValue('productSku', productSku);
    }, [productSku, setValue]);

    return (
        <form className={'flex flex-col gap-4'} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => (
                <div key={index} className={'flex flex-col gap-1'}>
                    {field.type === 'text' && (
                        <input
                            {...register(field.name, field.validation)}
                            className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                'rounded-3xl border border-gray-400 w-full h-12 p-4'}
                            placeholder={field.placeholder}
                        />
                    )}
                    {field.type === 'textarea' && (
                        <textarea
                            {...register(field.name, field.validation)}
                            className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                                'rounded-3xl border border-gray-400 w-full p-4'}
                            rows={field.rows || 4}
                            placeholder={field.placeholder}
                        />
                    )}
                    {field.type === 'citylist' && (
                        <CityList register={register}/>
                    )}
                    <ErrorMessage
                        errors={errors}
                        name={field.name}
                        render={({message}) => (
                            <span className={'react-css error-message text-red-500 text-base'}>{message}</span>
                        )}
                    />
                </div>
            ))}
            <div className={'flex items-center justify-center gap-2 w-full mb-6'}>
                <button
                    className={
                        'rounded-full bg-first-btn-bg hover:bg-first-btn-bg focus:bg-first-btn-bg ' +
                        'font-montserrat font-bold text-lg text-center text-white focus:text-white hover:text-white ' +
                        'shadow-first-btn-shadow transform hover:translate-y-1 mt-2 w-80 py-3 px-4 flex items-center justify-center ' +
                        (loading ? 'opacity-50 cursor-not-allowed' : '')
                    }
                    type="submit"
                    disabled={loading}
                >
                    {loading ? 'Se trimite ...' : 'Trimite cererea'}
                </button>
            </div>
        </form>
    );
};
