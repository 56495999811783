import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {createProductReview, productReviewRatingsMetadata} from '../../queries';

export type ProductItemType = {
    name: string;
};

type ProductViewStateType = {
    config: any;
    handleAddReview: (review: any) => void;
    reviewRatings: ReviewRatingsType[] | [];
    neededRating: any;
};

type ProductViewContextType = ProductViewStateType & {}

type configType = {}

type RatingValue = {
    value_id: string;
    value: string;
};

type ReviewRatingsType = {
    id: string;
    name: string;
    values: RatingValue[];
};

interface CreateProductReviewRequest {
    nickname: string;
    summary: string;
    text: string;
    ratings: {
        rating_name: string;
        rating_value: string;
    };
}

const ProductViewStateContext = createContext<ProductViewContextType | undefined>(undefined);

export const ProductViewProvider: React.FC<{ children: React.ReactNode, config: configType }> = (
    {
        children,
        config
    }) => {

    const qualityRating = 'Calitate';

    const [reviewRatings, setReviewRatings] = useState<ReviewRatingsType[]>([]);
    const neededRating = reviewRatings.find(rating => rating.name === qualityRating);

    const productReviewRatingsQuery = useQuery({
        queryKey: ['reviewRatings'],
        queryFn: async () => {
            return request(productReviewRatingsMetadata);
        },
        enabled: true,
        onSuccess: (data) => {
            setReviewRatings(data.data.productReviewRatingsMetadata.items);
        },
    });

    useEffect(() => {
        if (productReviewRatingsQuery?.data) {
            setReviewRatings(productReviewRatingsQuery?.data?.data?.productReviewRatingsMetadata?.items);
        }
    },[productReviewRatingsQuery.data]);

    const mutation = useMutation((input: CreateProductReviewRequest) =>
        request(createProductReview, {input})
    );

    const handleAddReview = (review: any) => {
        mutation.mutate(review);
    }

    return (
        <ProductViewStateContext.Provider value={
            {
                reviewRatings,
                neededRating,
                config,
                handleAddReview
            }
        }>
            {children}
        </ProductViewStateContext.Provider>
    );
}

export const useProductViewState = () => {
    const context = useContext(ProductViewStateContext);
    if (context === undefined) {
        throw new Error("useProductViewState must be used within a ProductViewProvider");
    }
    return context;
}
