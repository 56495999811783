import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const ShortDescriptionSkeleton = () => {
    return (
        <div className="w-full h-40 flex flex-col justify-start gap-3 mb-3 xl:mb-5 bg-cards-bg">
            <Skeleton width={'100%'} className={'w-full h-40'}/>
        </div>
    );
};
