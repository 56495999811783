import React from "react";
import {useProductAttributes} from "../../../state";
import {PageTitleSkeleton} from "../Skeleton/PageTitleSkeleton";

export const PageTitle = () => {
    const {productName, loading} = useProductAttributes() || {};

    if (loading) {
        return <PageTitleSkeleton/>;
    }

    return (
        <div className={'flex flex-col justify-start gap-1 mb-2 xl:mb-4 w-full'}>
            <h1 className={'react-css font-montserrat text-2xl xl:text-3xl font-bold text-custom-gray text-left'}>
                {productName}
            </h1>
        </div>
    );
}
