import React from "react";
import {CostTransport} from "./ProductInfo/CostTransport";
import {ProductAttributeProvider} from "../../state";
import {client} from "../../../Infrastructure/models/client";
import {QueryClientProvider} from "@tanstack/react-query";
import {PageTitle} from "./ProductInfo/PageTitle";
import {InfoReviews} from "./ProductInfo/InfoReviews";
import {StockStatus} from "./ProductInfo/StockStatus";
import {PriceInfo} from "./ProductInfo/PriceInfo";
import {BundleCheckoutProvider, CheckoutProvider} from "../../../Checkout";
import {TbiCard} from "./ProductInfo/TbiCard";
import {ProductOptions} from "./ProductInfo/ProductOptions";
import {BundleAddToCartButton} from "./AddToCart/BundleAddToCartButton";
import {ShortDescription} from "./ProductInfo/ShortDescription";
import {Contacts} from "./ProductInfo/Contacts";
import {AddToCartButton} from "./AddToCart/AddToCartButton";
import {LinkedProducts} from "./ProductInfo/LinkedProducts";
import {ProductSideBar} from "./ProductSideBar";

interface ProductInfoProps {
    productSku: string;
    categorySettings: Record<string, string>;
}

const queryClient = client();

export const ProductInfo: React.FC<ProductInfoProps> = ({productSku, categorySettings}) => {
    return (
        <QueryClientProvider client={queryClient}>

            <CheckoutProvider>
                <BundleCheckoutProvider productSku={productSku}>
                    <ProductAttributeProvider productSku={productSku}>
                        <div className={'flex flex-col md:flex-row justify-between'}>
                            <div className={'flex flex-col w-full md:w-[65%] '}>
                                <PageTitle/>
                                <p className={'react-css font-montserrat text-sm xl:text-base text-text-gray mb-2 xl:mb-4 text-start'}>
                                    {'Cod articol: '}
                                    <span className={'text-custom-gray'}>{productSku}</span>
                                </p>
                                <InfoReviews/>
                                <PriceInfo/>
                                <CostTransport categorySettings={categorySettings}/>
                                <StockStatus/>
                                <LinkedProducts/>
                                <ProductOptions/>
                                <AddToCartButton/>
                                <BundleAddToCartButton/>
                                <TbiCard/>
                                <ShortDescription/>
                                <Contacts/>
                            </div>

                            <div className={'w-full md:w-[30%]'}>
                                <ProductSideBar/>
                            </div>
                        </div>
                    </ProductAttributeProvider>
                </BundleCheckoutProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
}
