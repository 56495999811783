import React from 'react';
import {CategoryButton} from './CategoryButton';
import {Blocks} from './Blocks';
import {CategoriesProductsStateProvider} from "../../state";
import {Arrows} from '../../../../general';
import { useWindowSize } from '../../../../general';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";

const queryClient = client();

export const BlackFridayPage: React.FC = () => {
    const { isMobile } = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
        <CategoriesProductsStateProvider>
            <div className={'react-css xl:mt-5 xl:mt-10 pt-3 pb-3 pl-4 bg-background-gray xl:bg-inherit flex items-center flex-row gap-2'}>
                {isMobile ? (
                    <Arrows
                        arrowType={'LeftArrow'}
                        fill={'#848484'}
                        arrowSize={'h-3 w-3'}
                    />
                ) : (
                    <Arrows
                        arrowType={'LeftArrow'}
                        fill={'#F0F0F0'}
                        arrowSize={'h-3 w-3'}
                    />
                )}
                <a className={'react-css font-nunito font-bold text-text-gray xl:text-white text-base xl:active:text-white xl:hover:text-white xl:focus:text-white decoration-current'}
                   href={`${window.BASE_URL}home`}>Inapoi la pagina principala </a>
            </div>
            <div>
                <div className="flex flex-col gap-6 justify-center items-center w-full">
                    <Blocks/>
                </div>
                <div>
                    <CategoryButton/>
                </div>
            </div>
        </CategoriesProductsStateProvider>
        </QueryClientProvider>
    )
};


