import React from 'react';
import {useProductAttributes} from '../../../state';
import {Stars} from "../../../../../general";
import {InfoReviewsSkeleton} from "../Skeleton/InfoReviewsSkeleton ";

interface Review {
    summary: string;
    text: string;
    nickname: string;
    created_at: string;
    average_rating: number;
    ratings_breakdown: {
        name: string;
        value: string;
    }[];
}

const preprocessReviews = (reviews: Review[]): Review[] => {
    return reviews.map(review => {
        const dateOnly = review.created_at.split(" ")[0];
        const defaultRating = {
            name: 'default_code',
            value: '5',
        };

        return {
            summary: review.summary,
            text: review.text,
            nickname: review.nickname,
            created_at: dateOnly,
            average_rating: review.ratings_breakdown.length > 0 ? review.average_rating : 5,
            ratings_breakdown: review.ratings_breakdown.length > 0 ? review.ratings_breakdown : [defaultRating],
        };
    });
};

export const InfoReviews = () => {
    const {productAttributes, loading} = useProductAttributes() || {};
    const preprocessedReviews = preprocessReviews(productAttributes?.reviews?.items || []);
    const numberOfReviews = preprocessedReviews.length;

    const totalStarsValue = preprocessedReviews.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, rating) => rSum + Number(rating.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = parseFloat((totalStarsValue / numberOfReviews).toFixed(1));

    if (loading) {
        return <InfoReviewsSkeleton/>;
    }

    return (
        <>
            {!productAttributes || productAttributes?.reviews?.items.length === 0 ? (
                <a
                    className={'react-css flex flex-row items-center justify-start xl:justify-start font-montserrat text-sm xl:text-lg hover:underline gap-2 mb-2 xl:mb-4 ' +
                        'text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}
                    href={'#review-form'}>
                    {'Adauga o evaluare'}
                </a>
            ) : (
                <div
                    className={'react-css flex flex-row items-center justify-between gap-1 text-custom-gray  mb-2 xl:mb-4'}>
                    <div className={'react-css flex flex-row items-center justify-start gap-1 text-custom-gray '}>
                        <Stars totalStars={averageStars} starSize={'h-6 w-6'}/>
                        <a className={'flex-row items-center font-bold text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray text-sm xl:text-lg'}
                           href={'#react-reviews'}>
                            {numberOfReviews}
                        </a>
                    </div>

                    <a
                        className={'react-css flex flex-row items-center justify-start xl:justify-start font-montserrat text-sm xl:text-lg hover:underline gap-2 ' +
                            'font-bold text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}
                        href={'#review-form'}>
                        {'Adauga o evaluare'}
                    </a>
                </div>
            )}
        </>
    );
}
