import React from 'react';
import {useProductAttributes} from "../../../state";

export const Avantaje = () => {
    const {categories} = useProductAttributes() || {};
    const hasCategory = categories?.some(category => category.id === 332);

    return (
        <div className={`flex flex-col items-start gap-3`}>
            <div className={'react-css cursor-pointer flex flex-row items-center gap-3'}>
                <svg className={'fill-current text-gray-500 w-6 h-6'}
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16
                        108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1
                        472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9
                        73.3c-3.3 151.4-82.1 261.1-175.8 307.7z"/>
                </svg>
                <div className={'react-css relative group inline-block'}>
                    <p className={'react-css text-custom-gray font-nunito text-sm ' +
                        'text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}>
                        {'Garantie'}
                    </p>
                    {hasCategory ? (
                        <span className={'absolute bg-white max-w-[360px] min-w-[250px] ' +
                            'p-4 z-50 hidden group-hover:block left-[-50px] top-full text-left text-gray-500 ' +
                            'border border-gray-400 mt-1.5 text-sm'}>
                            {`La acest produs aveti garantie 5 ANI (atat pentru persoane fizice cat si pentru persoane juridice).`}
                        </span>
                    ) : (
                        <span className={'absolute bg-white max-w-[360px] min-w-[250px] ' +
                            'p-4 z-50 hidden group-hover:block left-[-50px] top-full text-left text-gray-500 ' +
                            'border border-gray-400 mt-1.5 text-sm'}>
                        {`La acest produs aveti garantie 24 de luni pentru persoane fizice si 12 luni pentru persoane juridice.`}
                    </span>
                    )}
                </div>
            </div>

            <div className={'react-css cursor-pointer flex flex-row items-center gap-3'}>
                <svg className={'fill-current text-gray-500 w-6 h-6'}
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M507.73 109.1c-2.24-9.03-13.54-12.09-20.12-5.51l-74.36 74.36-67.88-11.31-11.31-67.88
                        74.36-74.36c6.62-6.62 3.43-17.9-5.66-20.16-47.38-11.74-99.55.91-136.58 37.93-39.64 39.64-50.55 97.1-34.05
                        147.2L18.74 402.76c-24.99 24.99-24.99 65.51 0 90.5 24.99 24.99 65.51 24.99 90.5 0l213.21-213.21c50.12 16.71
                        107.47 5.68 147.37-34.22 37.07-37.07 49.7-89.32 37.91-136.73zM64 472c-13.25 0-24-10.75-24-24 0-13.26
                        10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"/>
                </svg>
                <div className={'react-css relative group inline-block'}>
                    <p className={'react-css text-custom-gray font-nunito text-sm ' +
                        'text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}>
                        {'Service autorizat'}
                    </p>
                    <span className={'absolute bg-white max-w-[360px] min-w-[250px] ' +
                        'p-4 z-50 hidden group-hover:block left-[-50px] top-full text-left text-gray-500 ' +
                        'border border-gray-400 mt-1.5 text-sm'}>
                        {`Costica este autorizat sa iti repare in garantie si post garantie produsele ce le comercializeaza.`}
                    </span>
                </div>
            </div>
        </div>
    );
}
