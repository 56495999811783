import React, {useState} from 'react';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {CheckoutProvider} from "../../../Checkout";
import {CrmForms} from "./CrmForms";
import {ContextFormsProvider} from "../../state";

const queryClient = client();

interface ProductSku {
    productSku: string;
    title: string;
}

export const CrmLeadFormApp: React.FC<ProductSku> = ({productSku, title}) => {
    const [isFormOpen, setIsFormOpen] = useState(false);

    console.log('CrmLeadFormApp', productSku, title);

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ContextFormsProvider productSku={productSku}>
                    <div className={'flex items-center justify-center'}>
                        <CrmForms
                            title={title}
                            onClose={handleCloseForm}
                        />
                    </div>
                </ContextFormsProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};
