import { useState, useEffect } from "react";

export const BreakPoints = {
    screenM: 767,
    screenL: 1279
};

type WindowSize = {
    width: number | undefined;
    height: number | undefined;
    isMobile: boolean | undefined;
    isTablet: boolean | undefined;
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
        isMobile: undefined,
        isTablet: undefined
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowSize({
                width: width,
                height: window.innerHeight,
                isMobile: width < BreakPoints.screenM,
                isTablet: width >= BreakPoints.screenM && width <= BreakPoints.screenL
            });
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        width: windowSize.width,
        height: windowSize.height,
        isMobile: windowSize.isMobile,
        isTablet: windowSize.isTablet
    };
};
