// @ts-nocheck
import { QueryClient } from '@tanstack/react-query'

export const client = () => {
    if (window.queryClient) {
        return window.queryClient
    }

    window.queryClient = new QueryClient({
        defaultOptions: {
            queries: {

            },
        },
    })
    return window.queryClient
}
