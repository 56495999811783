import React, {createContext, useContext, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {createLeadsForm} from '../../queries';

export type CreateFormRequestType = {
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    region: string;
    message?: string;
    productSku: string;
    title?: string;
};

type ContextFormStateType = {
    handleCreateForm: (formData: { [p: number]: any; [p: symbol]: any; productSku: any }) => void;
    productSku: string;
    successMessage: string;
    errorMessage: string;
    loading: boolean;
};

const ContextFormStateContext = createContext<ContextFormStateType | undefined>(undefined);

export const ContextFormsProvider: React.FC<{ children: React.ReactNode, productSku: string}> = ({ children, productSku }) => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const mutation = useMutation((formData: CreateFormRequestType) =>
        request(createLeadsForm, {input: formData}),
        {
            onSuccess: (data) => {
                if (data.data.createLeadsForm.success) {
                    setSuccessMessage(data.data.createLeadsForm.message);
                }
            },
            onError: (error: any) => {
                setErrorMessage('Ne pare rău, există o problemă la trimiterea mesajului. Vă rugăm folosiți numerele de telefon disponibile.');
                setSuccessMessage('');
            }
        }
    );

    const handleCreateForm = (formData: CreateFormRequestType) => {
        mutation.mutate(formData);
    }

    return (
        <ContextFormStateContext.Provider value={{
            handleCreateForm,
            productSku,
            successMessage,
            errorMessage,
            loading: mutation.isLoading
        }}>
            {children}
        </ContextFormStateContext.Provider>
    );
}

export const useContextFormsState = () => {
    const context = useContext(ContextFormStateContext);
    if (context === undefined) {
        throw new Error("useContextFormState must be used within a ContextFormProvider");
    }
    return context;
}
