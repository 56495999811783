import React from 'react';
import {useProductAttributes} from "../../../state";

export const TransportGratuit = () => {
    const {urgentMinPrice, urgentMaxWeight, productFinalPrice, productWeight, tipProdus} = useProductAttributes() || {};

    const minPrice = urgentMinPrice ? parseFloat(urgentMinPrice) : null;
    const maxWeight = urgentMaxWeight ? parseFloat(urgentMaxWeight) : null;
    const weight = productWeight ? parseFloat(productWeight) : null;

    if (minPrice !== null && maxWeight !== null && productFinalPrice !== null && weight !== null) {
        if (minPrice < productFinalPrice && weight < maxWeight && tipProdus !== 'Utilaje') {
            return (
                <div className={'flex flex-row items-center gap-3'}
                     id={'transport-gratuit-react'}>
                <svg className={'fill-current text-gray-500 w-6 h-6'}
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M628.88 210.65L494.39 49.27A48.01 48.01 0 0 0 457.52 32H32C14.33 32
                        0 46.33 0 64v288c0 17.67 14.33 32 32 32h32c0 53.02 42.98 96 96 96s96-42.98
                        96-96h128c0 53.02 42.98 96 96 96s96-42.98 96-96h32c17.67 0 32-14.33 32-32V241.38c0-11.23-3.94-22.1-11.12-30.73zM64
                        192V96h96v96H64zm96 240c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48
                        48zm160-240h-96V96h96v96zm160 240c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm-96-240V96h66.02l80 96H384z"/>
                </svg>
                    <div className={'react-css relative group inline-block cursor-pointer'}>
                        <p className={'react-css text-custom-gray font-nunito text-sm ' +
                            'text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}>
                            {'Transport gratuit'}
                        </p>
                        <span className={'absolute bg-white max-w-[360px] min-w-[250px] ' +
                            'p-4 z-50 hidden group-hover:block left-[-50px] top-full text-left text-gray-500 ' +
                            'border border-gray-400 mt-1.5 text-sm'}>
                        {`Transport gratuit pentru colete peste ${urgentMinPrice} lei si greutate sub ${urgentMaxWeight} kg, pe teritoriul Romaniei.`}
                    </span>
                    </div>
                </div>
            );
        }
    }
    return null;
}
