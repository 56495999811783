import React from 'react';
import { useProductAttributes } from "../../../state";

export const CostTransport = ({ categorySettings }) => {
    const { categories } = useProductAttributes() || {};

    if (!categories || categories.length === 0 || !categorySettings) {
        return null;
    }

    const renderDeliveryCost = () => {
        if (typeof categorySettings !== 'object') {
            return null;
        }

        for (const category of categories) {
            const categoryId = category.id.toString();
            if (Object.prototype.hasOwnProperty.call(categorySettings, categoryId)) {
                const { shipping_value, punere_in_functiune } = categorySettings[categoryId];
                const punereInFunctiuneText = punere_in_functiune ? ' si punere in functiune' : '';

                return (
                    <p className={'react-css font-bold text-left text-black-friday-btn font-nunito text-xl'}>
                        {`Cost livrare${punereInFunctiuneText}: ${shipping_value} ron cu tva inclus (oriunde in tara).`}
                    </p>
                );
            }
        }
        return null;
    };

    return (
        <div className={'react-css flex flex-col items-left'}>
            {renderDeliveryCost()}
        </div>
    );
};
