import React from 'react';

const ArrowLeft = ({fill = '#000000', arrowSize = "h-6 w-6"}) => (
    <svg fill={fill}
         className={arrowSize}
         viewBox="0 0 490 490" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
        <g>
            <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507"/>
        </g>
    </svg>
);

export const Arrows = ({arrowType, arrowSize = "h-6 w-6", fill = '#FFFFFF'}) => {
    const renderArrow = () => {
        switch (arrowType) {
            case 'arrowLeft':
                return <ArrowLeft fill={fill} arrowSize={arrowSize}/>;
            default:
                return <ArrowLeft fill={fill} arrowSize={arrowSize}/>;
        }
    };

    return (
        <div>
            {renderArrow()}
        </div>
    );
};
