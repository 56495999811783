export const commonFormFields = [
    {
        name: 'lastName',
        type: 'text',
        placeholder: 'Nume *',
        validation: {
            required: 'Numele este obligatoriu',
            maxLength: {
                value: 30,
                message: 'Maxim 30 caractere',
            },
        },
    },
    {
        name: 'firstName',
        type: 'text',
        placeholder: 'Prenume *',
        validation: {
            required: 'Prenumele este obligatoriu',
            maxLength: {
                value: 30,
                message: 'Maxim 30 caractere',
            },
        },
    },
    {
        name: 'email',
        type: 'text',
        placeholder: 'Email *',
        validation: {
            required: 'Acest camp este obligatoriu',
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Adresa de email invalidă',
            },
            maxLength: {
                value: 100,
                message: 'Maxim 100 caractere',
            },
        },
    },
    {
        type: 'text',
        name: 'phone',
        placeholder: 'Numar telefon *',
        validation: {
            required: 'Acest camp este obligatoriu',
            maxLength: {
                value: 15,
                message: 'Te rugam sa introduci un nr de telefon valid.'
            },
            minLength: {
                value: 10,
                message: 'Te rugam sa introduci un nr de telefon valid.'
            }
        }
    },
    {
        type: 'citylist',
        name: 'region',
        placeholder: 'Alege Județul',
        validation: {
            required: 'Selectarea unui județ este obligatorie'
        }
    },
    {
        type: 'textarea',
        name: 'message',
        placeholder: 'Mesajul tau ... (optional)',
        validation: {
            maxLength: {
                value: 200,
                message: 'Te rugam sa introduci mai putin de 200 caractere.'
            }
        },
        rows: 6
    }
];
