import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useWindowSize} from "../../../../../general";

export const SliderSkeleton = () => {
    const {isMobile} = useWindowSize();

    return (
        <div
            className={'h-[380px] xl:h-[470px] flex flex-col items-start justify-center xl:justify-start mb-3 mt-3 xl:mb-5 gap-3 bg-cards-bg'}>

            <div className={'h-[50px] bg-cards-bg w-full'}>
                <Skeleton width={'100%'} height={40} className={'w-full p-2'}/>
            </div>
            <div className={'flex flex-row items-start justify-center xl:justify-start mb-3 mt-3 w-full h-[300px] xl:h-[400px]'}>
                {isMobile ? (
                    <>
                        <div className={'w-1/2 h-[300px] xl:h-[400px] bg-white mr-1 ml-1  '}>
                            <Skeleton width={'50%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                        <div className={'w-1/2 h-[300px] xl:h-[400px] bg-white p-1 mr-1 ml-1 '}>
                            <Skeleton width={'50%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'w-1/5 h-[300px] xl:h-[400px] bg-white mr-1 ml-1  '}>
                            <Skeleton width={'100%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                        <div className={'w-1/5 h-[300px] xl:h-[400px] bg-white p-1 mr-1 ml-1 '}>
                            <Skeleton width={'100%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                        <div className={'w-1/5 h-[300px] xl:h-[400px] bg-white p-1 mr-1 ml-1 '}>
                            <Skeleton width={'100%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                        <div className={'w-1/5 h-[300px] xl:h-[400px] bg-white p-1 mr-1 ml-1 '}>
                            <Skeleton width={'100%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                        <div className={'w-1/5 h-[300px] xl:h-[400px] bg-white p-1 mr-1 ml-1 '}>
                            <Skeleton width={'100%'} className={'w-full h-full'} baseColor="white"/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
