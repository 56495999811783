import React, {useState, useEffect} from 'react';
import {FormFields} from './FormFields';
import {useContextFormsState} from '../../state';
import {commonFormFields} from './FormFieldsConfig';

export const CrmForms = ({onClose, title}) => {
    const {handleCreateForm, productSku, successMessage, errorMessage} = useContextFormsState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const onSubmit = (formData) => {
        handleCreateForm({ ...formData, title });
    };

    useEffect(() => {
        if (successMessage) {
            setIsModalOpen(true);
            setFormSubmitted(true);
        }
    }, [successMessage]);

    const handleClose = () => {
        onClose();
        setIsModalOpen(false);
    };

    return (
        <div className={'relative react-css flex items-center justify-center align-center gap-2 w-full xl:w-1/2 xl:max-w-4xl pl-4 pr-4'}>
            {!formSubmitted ? (
                <div className={'react-css crm-lead-form !mt-5 !mb-5 flex flex-col space-y-2.5 rounded-xl bg-cards-bg shadow-custom-box-shadow px-3 py-3 xl:px-8 xl:py-5 block w-full'}>
                    <button onClick={onClose} className={'absolute font-2xl right-[5%] top-[5%]'}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={'h-8 w-8'} viewBox="0 0 512 512">
                            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                        </svg>
                    </button>
                    {errorMessage && (
                        <div className={'react-css error-message text-red-500 text-center mb-4 text-lg font-nunito'}>
                            {errorMessage}
                        </div>
                    )}
                    <p className={'react-css text-center text-slate-700 font-bold text-lg leading-normal mb-4'}>
                        {title}
                    </p>
                    <FormFields onSubmit={onSubmit} fields={commonFormFields} productSku={productSku} />
                </div>
            ) : (
                isModalOpen && (
                    <div className={'fixed top-0 left-0 w-full h-full flex items-center justify-center z-40'}>
                        <div className={'react-css bg-background-gray p-6 rounded-xl shadow-lg w-4/5 xl:w-96 xl:max-w-96 p-6 ' +
                            'z-50 absolute top-24 flex items-center justify-center flex-col border-solid border-2 border-main-orange'}>
                            <p className={'react-css text-center'}>{successMessage}</p>
                            <button onClick={handleClose}
                                    className={'react-css mt-4 bg-main-orange text-white px-4 py-2 rounded hover:bg-main-orange'}
                                    type="button">
                                {'Inchide'}
                            </button>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
