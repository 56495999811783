import React, {useEffect, useState} from 'react';
import {ContextFormsProvider, useContextFormsState} from '../../../state';
import {FormFields} from '../FormFields';
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {commonFormFields} from '../FormFieldsConfig';

export const ContactPageForm = () => {
    const [showCrmLeadForm, setShowCrmLeadForm] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const packageNames = [
        'Solicit detalii legate de produsele dumneavoastra',
        'Tractor SH',
        'Piese de schimb',
        'Sunt interesat sa achizitionez',
        'Va propun o colaborare',
        'Alt subiect',
        'Reclamatii',
        'Interventie service',
        'Agriplanta 2016',
        'Retur produs',
        'Info produs',
    ];

    const FormWithContext = () => {
        const {handleCreateForm, successMessage: contextSuccessMessage} = useContextFormsState();
        const [selectedPackage, setSelectedPackage] = useState('');
        const {register, formState: {errors}, handleSubmit, watch, setValue} = useForm();

        const watchPackage = watch('package');

        useEffect(() => {
            if (watchPackage) {
                setSelectedPackage(watchPackage);
            }
        }, [watchPackage]);

        useEffect(() => {
            if (packageNames.length > 0) {
                setValue('package', packageNames[0]);
                setSelectedPackage(packageNames[0]);
            }
        }, [packageNames, setValue]);

        const onSubmit = (formData) => {
            if (!selectedPackage) {
                return;
            }
            const updatedFormData = {
                ...formData,
                productSku: selectedPackage,
                title: selectedPackage
            };
            handleCreateForm(updatedFormData);
        };

        useEffect(() => {
            if (contextSuccessMessage) {
                setSuccessMessage(contextSuccessMessage);
                setFormSubmitted(true);
                setIsModalOpen(true);
                setShowCrmLeadForm(false);
            }
        }, [contextSuccessMessage]);

        return (
            <>
                <div className={'flex flex-col gap-1 mb-4'}>
                    <p className={'react-css text-left font-black font-montserrat text-sm text-custom-gray tracking-wide mb-0 xl:mb-6 w-full'}>
                        {'Selecteaza un subiect:'}
                    </p>
                    <select
                        {...register('package', {
                            required: 'Selectarea unui subiect este obligatorie'
                        })}
                        className={'react-css text-custom-gray font-nunito font-normal text-left text-base rounded-3xl border border-gray-400 w-full h-12 p-2'}
                    >
                        {packageNames.map(name => (
                            <option key={name} value={name}>{name}</option>
                        ))}
                    </select>
                    <ErrorMessage
                        errors={errors}
                        name="package"
                        render={({message}) => <span
                            className={'react-css font-nunito error-message text-red-500 text-base'}>{message}</span>}
                    />
                </div>
                <FormFields
                    fields={commonFormFields}
                    onSubmit={onSubmit}
                    productSku={selectedPackage}
                />
            </>
        );
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={'flex flex-col items-center mt-3 xl:mt-8 w-full'}>
            {showCrmLeadForm && (
                <ContextFormsProvider productSku={''}>
                    <div
                        className={'relative react-css flex flex-col items-center justify-center align-center gap-2 w-full pl-4 pr-4'}>
                        <p className={'react-css mt-3 xl:mt-8 text-center text-xl xl:text-2xl text-custom-gray font-montserrat font-bold'}>
                            {'Trimite-ne un mesaj: '}
                        </p>
                        <div
                            className={'react-css crm-lead-form flex flex-col space-y-2.5 rounded-xl bg-cards-bg shadow-custom-box-shadow xl:my-6 px-3 py-3 xl:px-8 xl:py-5 block w-full'}>
                            <FormWithContext/>
                        </div>
                    </div>
                </ContextFormsProvider>
            )}

            {formSubmitted && (
                <div className={'z-10'}>
                    {isModalOpen && (
                        <div className={'w-full h-full flex items-center justify-center'}>
                            <div
                                className={'react-css bg-background-gray p-6 rounded-xl shadow-lg w-4/5 h-4/5 p-6 ' +
                                    'flex items-center justify-center flex-col border-solid border-2 border-main-orange'}>
                                <p className={'react-css text-center'}>{successMessage}</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

