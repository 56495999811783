import axios from 'axios';

export const request = (query: {}, variables: {} = {}) => {
    return axios({
        method: 'POST',
        url: '/graphql',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            query: query,
            variables: variables
        }
    }).then(response => response.data)
        .catch(error => {
            throw error.response.data;
        });
};
