import React from 'react';
import {useProductAttributes} from "../../../state";

export const Brand = () => {
    const {productLogo} = useProductAttributes() || {};

    if (!productLogo) {
        return null;
    }

    const formattedManufacturerName = typeof productLogo?.attributes?.manufacturerName === 'string'
        ? productLogo.attributes.manufacturerName.replace(/ /g, '-').toLowerCase() : '';

    const manufacturerUrl = `${productLogo?.attributes?.baseUrl}producator/${formattedManufacturerName}`;

    const generateProductLogoSrc = () => {
        const manufacturerName = typeof productLogo?.attributes?.manufacturerName === 'string'
            ? productLogo.attributes.manufacturerName
            : '';
        if (manufacturerName.includes(' ')) {
            const nameForLogo = manufacturerName.replace(/ /g, '_').toLowerCase();
            return productLogo?.attributes?.productLogo.replace(manufacturerName.toLowerCase(), nameForLogo);
        }
        return productLogo?.attributes?.productLogo;
    }

    const productLogoSrc = generateProductLogoSrc();

    return (
        <div className={'react-css rounded-xl bg-cards-bg p-4 block mb-4 mt-4 xl:mb-6 xl:mt-6 xl:mt-0 '}>
            <a className={'react-css flex flex-col items-center justify-center gap-5 text-custom-gray hover:text-current'}
               href={manufacturerUrl}>
                {productLogo?.attributes?.productLogoExists ?
                    <img className={'max-w-[90%]'} src={productLogoSrc} alt={productLogo?.attributes?.manufacturerName}/> :
                    <span className={'react-css text-center text-custom-gray font-nunito text-xl font-bold leading-4'}>{productLogo?.attributes?.manufacturerName}</span>
                }
                <span className={'react-css text-center text-custom-gray font-nunito text-sm'}>
                    {'Vezi produsele brandului'}
                </span>
            </a>
        </div>
    );
}
