import React from 'react';

export const CityList = ({register}) => {
    return (
        <div className={'flex flex-col gap-1 w-full'}>
            <select
                {...register('region', {
                    required: 'Selectarea unui județ este obligatorie',
                })}
                className={'react-css text-custom-gray font-montserrat font-normal text-left text-base ' +
                    'rounded-3xl border border-gray-400 w-full h-12 pl-4 pr-4'}>
                <option value="">Alege Județul</option>
                <option value="In afara României">In afara României</option>
                <option value="Alba">Alba</option>
                <option value="Arad">Arad</option>
                <option value="Arges">Arges</option>
                <option value="Bacau">Bacau</option>
                <option value="Bihor">Bihor</option>
                <option value="Bistrita-Nasaud">Bistrita-Nasaud</option>
                <option value="Botosani">Botosani</option>
                <option value="Brasov">Brasov</option>
                <option value="Braila">Braila</option>
                <option value="Buzau">Buzau</option>
                <option value="Calarasi">Calarasi</option>
                <option value="Caras-Severin">Caras-Severin</option>
                <option value="Cluj">Cluj</option>
                <option value="Constanta">Constanta</option>
                <option value="Covasna">Covasna</option>
                <option value="Dambovita">Dambovita</option>
                <option value="Dolj">Dolj</option>
                <option value="Galati">Galati</option>
                <option value="Giurgiu">Giurgiu</option>
                <option value="Gorj">Gorj</option>
                <option value="Harghita">Harghita</option>
                <option value="Hunedoara">Hunedoara</option>
                <option value="Ialomita">Ialomita</option>
                <option value="Iasi">Iasi</option>
                <option value="Ilfov">Ilfov</option>
                <option value="Maramures">Maramures</option>
                <option value="Mehedinti">Mehedinti</option>
                <option value="Mures">Mures</option>
                <option value="Neamt">Neamt</option>
                <option value="Olt">Olt</option>
                <option value="Prahova">Prahova</option>
                <option value="Satu Mare">Satu Mare</option>
                <option value="Salaj">Salaj</option>
                <option value="Sibiu">Sibiu</option>
                <option value="Suceava">Suceava</option>
                <option value="Teleorman">Teleorman</option>
                <option value="Timis">Timis</option>
                <option value="Tulcea">Tulcea</option>
                <option value="Vaslui">Vaslui</option>
                <option value="Valcea">Valcea</option>
                <option value="Vrancea">Vrancea</option>
                <option value="Bucuresti">Bucuresti</option>
            </select>
        </div>
    );
};
