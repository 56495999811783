import React from 'react';
import {useProductAttributes} from "../../../state";

export const AttachmentsComponent = () => {
    const {productAttachment} = useProductAttributes() || {};

    if (!productAttachment) {
        return null;
    }

    const attachments = productAttachment?.attachments ?? [];

    return (
        <div className={'react-css'} id={'hexamarvel-attachments'}>
            <ul className={'flex flex-col items-start gap-3'}>
                {attachments?.map((attachment, index) => (
                    <li key={index} className={'react-css flex flex-row gap-2 items-center justify-start !m-0'}>
                        <svg className={'fill-current text-gray-500 w-6 h-6'}
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7
                                64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4
                                24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6
                                0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/>
                        </svg>
                        <div className={'react-css relative group inline-block'}>
                            <a className={'react-css font-nunito text-sm ' +
                                'text-custom-gray focus:text-custom-gray active:text-custom-gray visited:text-custom-gray hover:text-custom-gray'}
                               href={attachment.fileUrl} target="_blank" rel="noopener noreferrer">
                                {attachment.name}
                            </a>
                            <span className={'absolute bg-white max-w-[360px] min-w-[250px] ' +
                                'p-4 z-50 hidden group-hover:block left-[-50px] top-full text-left text-gray-500 ' +
                                'border border-gray-400 mt-1.5 text-sm'}>
                                {'Descarca fisierul.'}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
