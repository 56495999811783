import React, {useState} from 'react';
import {ContextFormsProvider} from '../../../state';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../../Infrastructure/models/client";
import {ContactPageForm} from "./ContactPageForm";

const queryClient = client();

export const ContactPageFormApp = () => {
    const [isFormOpen, setIsFormOpen] = useState(false);

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    return (
        <QueryClientProvider client={queryClient}>
            <ContextFormsProvider productSku={''}>
                <div className={'flex items-center justify-center mb-10'}>
                    <ContactPageForm/>
                </div>
            </ContextFormsProvider>
        </QueryClientProvider>
    );
};
