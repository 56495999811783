import React from 'react';
import {DistributionMap} from '../../../../../general';
import {useProductAttributes} from '../../../state';

interface SmallMapProps {
    hideOnLarge?: boolean;
}

export const SmallMap = ({hideOnLarge}: SmallMapProps) => {
    const {customAttributes} = useProductAttributes() || {};
    if (!customAttributes) {
        return null;
    }

    const f_tip_produs_attribute = customAttributes?.find(
        attribute => attribute.attribute_code === 'f_tip_produs'
    )?.value;

    if (f_tip_produs_attribute === 'Utilaje') {
        return (
            <div className={`flex items-center justify-center ${hideOnLarge ? 'lg:hidden' : ''} mt-6`}>
                <DistributionMap/>
            </div>
        );
    }
    return null;
}

