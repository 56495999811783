import React, {useState} from 'react';
import {CrmForms} from "../../../../CrmForms/components/CrmForms/CrmForms";
import {ContextFormsProvider} from "../../../../CrmForms";
import {useProductAttributes} from "../../../state";

interface ConfigType {
    imageUrl: string;
}

declare global {
    interface Window {
        imageUrlConfig: string;
    }
}

export const Seap = (props: ConfigType) => {
    const imageUrl = window.imageUrlConfig || props.imageUrl;
    const {productSku} = useProductAttributes() || {};

    // State to control if the form modal is open or closed
    const [isFormOpen, setIsFormOpen] = useState(false);

    if (!imageUrl) {
        return null;
    }

    // Function to handle opening the form
    const handleOpenForm = () => {
        setIsFormOpen(true);
    };

    // Function to handle closing the form
    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    return (
        <div
            className={'react-css rounded-xl bg-cards-bg flex flex-col p-4 mb-4 mt-4 xl:mb-6 xl:mt-6 items-center gap-3'}>
            <div className={'flex flex-row gap-3'}>
                <div className={'flex items-center justify-center w-1/2'}>
                    <img src={imageUrl} alt="SEAP Logo"/>
                </div>
                <div className={'react-css relative group inline-block w-1/2 leading-tight'}>
                    <p className={'react-css text-center text-custom-gray font-nunito text-xs'}>
                        Suntem prezenti pe
                    </p>
                    <p className={'react-css font-bold text-center text-custom-gray font-nunito text-xs'}>
                        SISTEMUL ELECTRONIC DE ACHIZITII PUBLICE
                    </p>
                </div>
            </div>


            <ContextFormsProvider productSku={productSku}>
                {/* Button to open the form */}
                <div className={'react-css text-center flex items-center justify-center w-full'}>
                    <button
                        className={'flex justify-center items-center h-[40px] ' +
                            'bg-main-orange focus:outline-none focus:bg-main-orange active:bg-main-orange ' +
                            'text-white text-base font-montserrat font-bold rounded-full w-4/5 xl:w-full p-3 xl:m-0 ' +
                            'hover:bg-main-orange hover:text-white focus:text-white active:text-white hover:translate-y-1 ' +
                            'shadow-first-btn-shadow border-main-orange transition-all duration-300 ease-in-out'}
                        onClick={handleOpenForm}
                    >
                        {'Cere postarea pe SEAP'}
                    </button>
                </div>

                {isFormOpen && (
                    <div
                        className={'fixed inset-0 flex items-center justify-center z-10'}>
                        <CrmForms
                            title="Cerere postare SEAP"
                            onClose={handleCloseForm}
                        />

                    </div>
                )}
            </ContextFormsProvider>
        </div>
    );
};
