import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {request} from '../../../Infrastructure';
import {
    getProductDescription,
    getProductRelated,
    getProductAttributes,
    getAutoRelatedSkus,
    getAutoRelatedProducts,
    customLinkedProducts,
} from '../../queries';
import {useQuery} from "@tanstack/react-query";

interface ProductAttributes {
    id;
    sku;
    name;
    type_id: string;
    stock_status: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    dim_lungime: string;
    dim_latime: string;
    dim_inaltime: string;
    categories?: {
        id: number;
        name: string;
        url_path: string;
    }[];
    price_range?: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
        discount?: {
            amount_off: { value: number; currency: string };
            percent_off: { value: number; currency: string }
        }
    };
    reviews?: {
        items?: {
            summary: string;
            text: string;
            nickname: string;
            created_at: string;
            average_rating: number;
            ratings_breakdown: {
                name: string;
                value: string;
            }[];
        }[];
    };
}

interface ProductDetails {
    id: string;
    sku: string;
    name: string;
    description?: {
        html
    };
    short_description?: {
        html
    };
    specificatii_tehnice;
}

interface ProductRelated {
    upsell_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
    }[];
    crosssell_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites?: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
    }[];
    related_products?: {
        id;
        sku;
        name;
        type_id: string;
        small_image?: { label: string; };
        customProductImageUrl?: { url: string; };
        price_range?: {
            minimum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string };
                discount: { amount_off: number; percent_off: number }
            };
            maximum_price?: {
                regular_price: { value: number; currency: string };
                final_price: { value: number; currency: string }
            }
        };
        url_rewrites?: {
            url: string;
            parameters?: {
                name: string;
                value: string;
            }[];
        }[];
        custom_attributes?: {
            attribute_code: string;
            value: string;
        }[];
    }[];
}

interface AutoRelatedSkus {
    id: string;
    sku: string;
    name: string;
}

interface LinkedProducts {
    id: string
    sku: string
    name: string
    url_path: string
    url_key: string
    custom_linked_position: string
    nume_alternativ: string
    farapret: string
    final_price: string
    produs_imperecheat_label: string
}

interface AutoRelatedProducts {
    id;
    sku;
    name;
    type_id: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    price_range: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
}

declare global {
    interface Window {
        appConfig: ConfigType;
        tbiParams: TbiParamsType;
        productLogo: ProductLogoType;
        fisaTehnica: FisaTehnicaType;
        productAttachments: AttachmentType;
        productManual: ManualType;
    }
}

type AttributeType = {
    label: string;
    value: string;
    code: string;
};

type ConfigType = {
    attributes: { [key: string]: AttributeType };
};

type TbiParamsType = {
    attributes: {
        tbi_status: string;
        tbi_btnvisible: string;
        tbi_custom_button_status: string;
        tbiro_vnoska: string;
        tierPrice: number;
        tbi_rate: string;
        tbi_commission: number;
        tbi_insurance: number;
        tbi_months: number;
        tbiro_mesecna: number;
        minprice_tbiro: string;
        maxprice_tbiro: string;
        tbi_btn_url: string;
        tbi_btn_hover_url: string;
        tbi_backurl: string;
        tbi_mod_version: string;
    }
};

type ProductLogoType = {
    attributes: {
        productLogo: string;
        manufacturerName: string;
        baseUrl: string;
        productLogoExists: boolean;
    }
};

type FisaTehnicaType = {
    technicalSheetUrl: string;
};

type AttachmentType = {
    attachments: {
        fileUrl: string;
        icon: string | null;
        name: string;
    }[];
    title: string;
};

type ManualType = {
    baseUrl: string;
    link: string;
};

interface ProductAttributeContextValue {
    productSku: string;
    tipProdus: string | null;
    productAttributes: ProductAttributes | null;
    loading: boolean;
    error: any;
    appConfig: ConfigType;
    upsellProducts: any[];
    relatedProducts: any[];
    crossSellProducts: any[];
    autoRelatedProducts: any[];
    linkedProducts: any[];
    productName: string;
    specificatii: string;
    shortDescription: string;
    description: string;
    customAttributes: {
        attribute_code: string;
        value: string;
    }[] | null;
    categories: {
        id: number;
        name: string;
        url_path: string;
    }[] | null;
    productWeight: string | null;
    productFinalPrice: number | null;
    urgentMinPrice: string | null;
    urgentMaxWeight: string | null;
    tbiParams: TbiParamsType | null;
    productLogo: ProductLogoType | null;
    fisaTehnica: FisaTehnicaType | null;
    productAttachment: AttachmentType | null;
    productManual: ManualType | null;
}

const ProductAttributeContext = createContext<ProductAttributeContextValue | undefined>(undefined);

export const ProductAttributeProvider: React.FC<{ children: ReactNode, productSku: string }> = (
    {
        children,
        productSku
    }
) => {
    const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
    const [productRelated, setProductRelated] = useState<ProductRelated | null>(null);
    const [productAttributes, setProductAttributes] = useState<ProductAttributes | null>(null);
    const [autoRelatedSkus, setAutoRelatedSkus] = useState<AutoRelatedSkus[] | []>([]);
    const [autoRelatedProducts, setAutoRelatedProducts] = useState<AutoRelatedProducts[] | []>([]);
    const [linkedProducts, setLinkedProducts] = useState<LinkedProducts[] | []>([]);
    const [appConfig, setAppConfig] = useState<ConfigType>({ attributes: {} });
    const [tbiParams, setTbiParams] = useState<TbiParamsType | null>(null);
    const [productLogo, setProductLogo] = useState<ProductLogoType | null>(null);
    const [fisaTehnica, setFisaTehnica] = useState<FisaTehnicaType | null>(null);
    const [productAttachment, setProductAttachment] = useState<AttachmentType | null>(null);
    const [productManual, setProductManual] = useState<ManualType | null>(null);
    const upsellProducts = productRelated?.upsell_products || [];
    const relatedProducts = productRelated?.related_products || [];
    const crossSellProducts = productRelated?.crosssell_products || [];
    const specificatii = productDetails?.specificatii_tehnice || null;
    const shortDescription = productDetails?.short_description.html || null;
    const description = productDetails?.description.html || null;
    const productName = productAttributes?.name || null;
    const customAttributes = productAttributes?.custom_attributes || null;
    const categories = productAttributes?.categories || null;

    const weight = findCustomAttributeValue(customAttributes, 'weight');
    const urgentMinPrice = findCustomAttributeValue(customAttributes, 'urgent_min_price');
    const urgentMaxWeight = findCustomAttributeValue(customAttributes, 'urgent_max_weight');

    const volumetricHeight = productAttributes?.dim_inaltime || null;
    const volumetricWidth = productAttributes?.dim_latime || null;
    const volumetricLength = productAttributes?.dim_lungime || null;
    const volumetricWeight = volumetricHeight && volumetricWidth && volumetricLength ?
        (parseFloat(volumetricHeight) * parseFloat(volumetricWidth) * parseFloat(volumetricLength) / 6000).toFixed(2) : null;

    const productFinalPrice = productAttributes?.price_range?.minimum_price?.final_price?.value;
    const tipProdus = findCustomAttributeValue(customAttributes, 'f_tip_produs');
    const weightValue = weight ? parseFloat(weight) : null;
    const volumetricWeightValue = volumetricWeight ? parseFloat(volumetricWeight) : null;
    let productWeight;
    if (weightValue !== null && volumetricWeightValue !== null) {
        productWeight = Math.max(weightValue, volumetricWeightValue);
    } else {
        productWeight = weightValue !== null ? weightValue : volumetricWeightValue;
    }

    const productDetailsQuery = useQuery({
        queryKey: ['productDetails'],
        queryFn: async () => {
            return request(getProductDescription, {sku: productSku});
        },
        enabled: true,
        // staleTime: 300000,
        refetchOnWindowFocus: false,
    });

    const productRelatedQuery = useQuery({
        queryKey: ['productRelated'],
        queryFn: async () => {
            return request(getProductRelated, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes'],
        queryFn: async () => {
            return request(getProductAttributes, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const autoRelatedSkusQuery = useQuery({
        queryKey: ['autoRelatedSkus', productSku],
        queryFn: async () => {
            const variables = {sku: productSku};
            return request(getAutoRelatedSkus, variables);
        },
        enabled: productSku !== null,
        refetchOnWindowFocus: false,
    });

    const autoRelatedProductsQuery = useQuery({
        queryKey: ['autoRelatedProducts', autoRelatedSkus],
        queryFn: async () => {
            const variables = {skus: autoRelatedSkus};
            return request(getAutoRelatedProducts, variables);
        },
        enabled: autoRelatedSkus?.length > 0,
        refetchOnWindowFocus: false,
    });

    const linkedProductsQuery = useQuery({
        queryKey: ['linkedProducts', productSku],
        queryFn: async () => {
            const variables = {sku: productSku};
            return request(customLinkedProducts, variables);
        },
        enabled: productSku !== null,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (productDetailsQuery?.data) {
            setProductDetails(productDetailsQuery?.data?.data?.products?.items[0]);
        }

        if (productRelatedQuery?.data) {
            setProductRelated(productRelatedQuery?.data?.data?.products?.items[0]);
        }

        if (productAttributesQuery?.data) {
            setProductAttributes(productAttributesQuery?.data?.data?.products?.items[0]);
        }

        if (autoRelatedSkusQuery?.data) {
            const skus = autoRelatedSkusQuery?.data?.data?.getAutoRelatedSkus.map(product => product.sku);
            setAutoRelatedSkus(skus);
        }

        if (autoRelatedProductsQuery?.data) {
            setAutoRelatedProducts(autoRelatedProductsQuery?.data?.data?.products?.items);
        }

        if (linkedProductsQuery?.data) {
            setLinkedProducts(linkedProductsQuery?.data?.data?.customLinkedProducts);
        }

    }, [
        productAttributesQuery.data,
        productDetailsQuery.data,
        productRelatedQuery.data,
        autoRelatedSkusQuery.data,
        autoRelatedProductsQuery.data,
        linkedProductsQuery.data
    ]);

    useEffect(() => {
        if (window.appConfig) {
            setAppConfig(window.appConfig);
        }
    }, []);

    useEffect(() => {
        if (window.tbiParams) {
            setTbiParams(window.tbiParams);
        }
    }, []);

    useEffect(() => {
        if (window.productLogo) {
            setProductLogo(window.productLogo);
        }
    }, []);

    useEffect(() => {
        if (window.fisaTehnica) {
            setFisaTehnica(window.fisaTehnica);
        }
    }, []);

    useEffect(() => {
        if (window.productAttachments) {
            setProductAttachment(window.productAttachments);
        }
    }, []);

    useEffect(() => {
        if (window.productManual) {
            setProductManual(window.productManual);
        }
    }, []);

    return (
        <ProductAttributeContext.Provider value={
            {
                productAttributes,
                loading: productAttributesQuery.isLoading,
                error: productAttributesQuery.error,
                appConfig,
                upsellProducts,
                relatedProducts,
                crossSellProducts,
                autoRelatedProducts,
                linkedProducts,
                productName,
                productSku,
                tipProdus,
                specificatii,
                shortDescription,
                description,
                customAttributes,
                categories,
                productWeight,
                productFinalPrice,
                urgentMinPrice,
                urgentMaxWeight,
                tbiParams,
                productLogo,
                fisaTehnica,
                productAttachment,
                productManual
            }
        }>
            {children}
        </ProductAttributeContext.Provider>
    );
};

export const findCustomAttributeValue = (customAttributes, attributeCode) => {
    if (!customAttributes || customAttributes.length === 0) return null;

    const attribute = customAttributes.find(attr => attr && attr.attribute_code === attributeCode);
    return attribute ? attribute.value : null;
};

export const useProductAttributes = () => {
    const context = useContext(ProductAttributeContext);
    if (!context) {
        throw new Error('useProductAttributes must be used within a ProductAttributeProvider');
    }
    return context;
};
