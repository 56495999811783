import React from 'react';
import {SmallMap} from './ProductSideBar/SmallMap';
import {SafetyStihl} from "./ProductSideBar/SafetyStihl";
import {Seap} from './ProductSideBar/Seap';
import {ProductAttributeProvider, useProductAttributes} from "../../state";
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {useWindowSize} from '../../../../general';
import {TransportGratuit} from "./ProductSideBar/TransportGratuit";
import {Avantaje} from "./ProductSideBar/Avantaje";
import {Brand} from "./ProductSideBar/Brand";
import {FisaTehnica} from "./ProductSideBar/FisaTehnica";
import {AttachmentsComponent} from "./ProductSideBar/AttachmentsComponent";
import {DescarcaManual} from "./ProductSideBar/DescarcaManual";

const queryClient = client();

export const ProductSideBar = () => {
    const {productSku} = useProductAttributes() || {};
    const imageUrl = window.imageUrlConfig;
    const {isMobile} = useWindowSize();

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <ProductAttributeProvider productSku={productSku}>
                    {isMobile ? (
                        <div>
                            <SmallMap/>
                            <Brand/>
                            <div className={'react-css rounded-xl bg-cards-bg flex flex-col px-7 py-4 xl:p-4 mb-4 mt-4 xl:mb-6 xl:mt-6 items-start gap-3'}>
                                <TransportGratuit/>
                                <Avantaje/>
                                <FisaTehnica/>
                                <AttachmentsComponent/>
                                <DescarcaManual/>
                            </div>
                            <SafetyStihl/>
                            <Seap imageUrl={imageUrl}/>
                        </div>
                    ) : (
                        <div>
                            <Brand/>
                            <div className={'react-css rounded-xl bg-cards-bg flex flex-col p-4 mt-6 mb-6 items-start gap-3'}>
                                <TransportGratuit/>
                                <Avantaje/>
                                <FisaTehnica/>
                                <AttachmentsComponent/>
                                <DescarcaManual/>
                            </div>
                            <SafetyStihl/>
                            <Seap imageUrl={imageUrl}/>
                            <SmallMap/>
                        </div>
                    )}
                </ProductAttributeProvider>
            </QueryClientProvider>
        </div>
    );
};


